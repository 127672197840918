.scoreViewerShownContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 0px;
}

.scoreViewerHiddenContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
    width: auto;
}

.placeholderImage {
    position: absolute;
    max-width: calc(var(--scoreViewerOptimalWidth) - 2 * var(--scoreViewerMargin) - 10px);
}


.toggleScoreButton {
    z-Index: 2;
    width: var(--toggleScoreButtonWidth);
    position: absolute;
    background-color: #ec3c3c;
    opacity: 0.5;
    cursor: pointer;
    border-radius: 4px;
    text-align: center;
}

.showScoreButton {
    composes: toggleScoreButton;
    z-Index: 6;
    right: var(--scoreViewerMargin);
}

.paddedCouplingButton {
    position: absolute;
    right: calc(var(--scoreViewerOptimalWidth) + 2*var(--scoreViewerMargin));
    z-index: 2;
    background-color: white;
}


.hintBox {
    composes: hintBox from "../../index.css";
    max-width: 10rem;
    height: fit-content;
    position: absolute;
    right: calc(var(--scoreViewerOptimalWidth) + 10px);
    top: calc(var(--headerHeight) + 60px);
    z-index: 6
}

.hintCaret {
    position: absolute;
    height: 2rem;
    color: #0e5855;
    right: -0.7rem;
}
